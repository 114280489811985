import React from "react";

import { getImage, GatsbyImage as Img } from "gatsby-plugin-image";
import { MDXRenderer } from "gatsby-plugin-mdx";

import { LinkList, SEO } from "@parataxic/shared-ui";

import { BookBox } from "../components/Book";
import { ParataxicMDXProvider } from "../components/MDXProvider";
import { PDFBox } from "../components/PDF";
import { PopoverSiteLink } from "../components/Popover";
import { getDescriptionFromPsychologist } from "../components/Psychologist";
import { VideoBox } from "../components/Video";

interface IPsychologistItemProps {
  readonly psychologist: GatsbyTypes.SitePageContextPsychologist;
  readonly mdx?: GatsbyTypes.SitePageContextMdx;
}

const PsychologistItem: React.FC<IPsychologistItemProps> = ({
  psychologist,
  mdx,
}) => {
  const {
    booksAuthored,
    booksMentionedIn,
    videosMentionedIn,
    videosCastedIn,
    pdfs,
    createdTheories,
    theoriesContributedTo,
    birthDay: birthYear,
    deathDay: deathYear,
  } = psychologist;
  const hasCreatedAndContributedToTheories = Boolean(
    createdTheories?.length && theoriesContributedTo?.length,
  );
  const hasMedia = Boolean(
    (videosMentionedIn && videosMentionedIn.length) ||
      (videosCastedIn && videosCastedIn.length) ||
      (pdfs && pdfs.length) ||
      (booksAuthored && booksAuthored.length) ||
      (booksMentionedIn && booksMentionedIn.length),
  );
  return (
    <>
      <div
        style={{
          gridTemplateColumns: "5fr 2fr", // mobile: 1fr, tablet 4fr 2fr
          gridGap: "6",
          display: "grid",
          padding: "1rem",
          width: "100%",
        }}
      >
        <div>
          <span
            style={{
              fontSize: hasCreatedAndContributedToTheories ? "1rem" : "1.25rem",
              fontStyle: "italic",
            }}
          >
            {createdTheories?.length > 0 && (
              <>
                <span>Founder of </span>
                <LinkList links={createdTheories} Component={PopoverSiteLink} />
              </>
            )}
            {theoriesContributedTo?.length > 0 && (
              <>
                <span>
                  {hasCreatedAndContributedToTheories
                    ? ", influenced "
                    : "Contributor "}
                  to
                </span>
                <LinkList
                  links={theoriesContributedTo}
                  Component={PopoverSiteLink}
                />
              </>
            )}
          </span>
          <h1
            style={{
              paddingBottom: "1rem",
              color: "var(--color-gray-800)",
            }}
          >
            {psychologist.fullName}{" "}
            {birthYear && deathYear && (
              <small style={{ color: "--var(color-gray-600)" }}>
                ({birthYear}-{deathYear})
              </small>
            )}
          </h1>
          <hr style={{ color: "var(--color-gray-300)" }} />
          <div
            style={{
              paddingTop: "1rem",
              fontStyle: "italic",
              fontSize: ".8rem",
              color: "rgba(0, 0, 0, .7)",
            }}
          >
            This is {`${!mdx?.body ? "a placeholder for " : ""}`}{" "}
            {psychologist.handle}&apos;s bio.
            {(createdTheories?.length > 0 ||
              theoriesContributedTo?.length > 0) && (
              <>
                {" "}
                For related theories see{" "}
                <LinkList links={createdTheories} Component={PopoverSiteLink} />
                {hasCreatedAndContributedToTheories && ", "}
                <LinkList
                  links={theoriesContributedTo}
                  Component={PopoverSiteLink}
                />
                {hasMedia && <>&nbsp;and the media content on the right</>}.
              </>
            )}
          </div>

          {mdx?.body && (
            <ParataxicMDXProvider>
              <MDXRenderer>{mdx?.body}</MDXRenderer>
            </ParataxicMDXProvider>
          )}
        </div>
        <div>
          {getImage(psychologist?.portraitSrc) && (
            <Img
              loading="eager"
              image={getImage(psychologist.portraitSrc)}
              alt={psychologist.fullName}
            />
          )}

          <VideoBox
            videos={videosCastedIn as GatsbyTypes.MdxVideo[]}
            title="Videos casted in"
          />
          <VideoBox
            videos={videosMentionedIn as GatsbyTypes.MdxVideo[]}
            title="Videos mentioned in"
          />

          <BookBox books={booksAuthored as GatsbyTypes.MdxBook[]} />
          <BookBox
            books={booksMentionedIn as GatsbyTypes.MdxBook[]}
            title="Books mentioned in"
          />
          <PDFBox pdfs={pdfs as GatsbyTypes.MdxPdf[]} />
        </div>
      </div>
    </>
  );
};

interface IProps {
  readonly pageContext: Pick<GatsbyTypes.SitePageContext, "psychologist"> & {
    readonly mdx?: GatsbyTypes.SitePageContextMdx;
  };
}

const View: React.FC<IProps> = ({ pageContext: { psychologist, mdx } }) => {
  const imageData = psychologist?.ogImage;
  const seoProps: { image?: string; description?: string; title?: string } = {};
  if (imageData) {
    seoProps.imageData = imageData;
  }
  if (psychologist) {
    seoProps.description = getDescriptionFromPsychologist(psychologist);
  }
  if (!psychologist) {
    return (
      <>
        <SEO title="Page not found" />
        <div>Psychologist not found</div>
      </>
    );
  }

  if (psychologist?.fullName) {
    seoProps.title = psychologist.fullName;
  }

  return (
    <>
      <SEO {...seoProps} />
      <div>
        <PsychologistItem psychologist={psychologist} mdx={mdx} />
      </div>
    </>
  );
};

export default View;
